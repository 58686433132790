import Vue from "vue";
import Vuex from "vuex";
import casefiles from "./modules/casefiles";
import dashboard from "./modules/dashboard";

import { auth } from "./auth.module";
import { fileModule } from "./file.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbars: [],
    snackbarQueue: [],
    maxVisibleSnackbars: 3,
    caseState: fileModule.state,
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      const newSnackbar = {
        ...snackbar,
        id: Date.now(),
        showing: true,
      };

      if (
        state.snackbars.filter((s) => s.showing).length >=
        state.maxVisibleSnackbars
      ) {
        state.snackbarQueue.push(newSnackbar);
      } else {
        state.snackbars.push(newSnackbar);
      }
    },
    REMOVE_SNACKBAR(state, snackbarId) {
      const index = state.snackbars.findIndex((s) => s.id === snackbarId);
      if (index !== -1) {
        state.snackbars.splice(index, 1);

        if (state.snackbarQueue.length > 0) {
          state.snackbars.push(state.snackbarQueue.shift());
        }
      }
    },
    CLEANUP_OLD_SNACKBARS(state) {
      state.snackbars = state.snackbars.filter((s) => s.showing);
    },
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      commit("SET_SNACKBAR", snackbar);
    },
  },

  modules: {
    auth,
    fileModule,
    casefiles,
    dashboard,
  },

  getters: {
    getCaseState: function (state) {
      return state.caseState;
    },
  },
  plugins: [
    createPersistedState({
      paths: ["dashboard"], // Only persist dashboard module
      storage: window.localStorage,
    }),
  ],
});
