<template>
  <v-container fluid>
    <!-- Page Header -->
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-title class="py-3 primary white--text">
            <v-icon left color="white">mdi-magnify</v-icon>
            Advanced Search Results
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              color="white"
              @click="$router.push('/dashboard')"
              class="mr-2"
            >
              <v-icon left small>mdi-view-dashboard</v-icon>
              Dashboard
            </v-btn>
          </v-card-title>
          <v-card-text class="py-2">
            <v-row>
              <!-- Search Filters Summary -->
              <v-col cols="12" md="8">
                <div class="d-flex flex-wrap align-center">
                  <strong class="mr-2">Filters:</strong>
                  <v-chip v-if="$route.query.keyword" small class="mr-2 mb-1">
                    Keyword: {{ $route.query.keyword }}
                  </v-chip>
                  <v-chip v-if="$route.query.dept" small class="mr-2 mb-1">
                    Department: {{ getDepartmentName($route.query.dept) }}
                  </v-chip>
                  <v-chip v-if="$route.query.branch" small class="mr-2 mb-1">
                    Branch: {{ getBranchName($route.query.branch) }}
                  </v-chip>
                  <v-chip v-if="$route.query.status" small class="mr-2 mb-1">
                    Status: {{ getStatusName($route.query.status) }}
                  </v-chip>
                  <v-chip v-if="$route.query.insurer" small class="mr-2 mb-1">
                    Insurer: {{ getInsurerName($route.query.insurer) }}
                  </v-chip>
                  <span v-if="!hasAnyFilters" class="grey--text"
                    >No filters applied</span
                  >
                </div>
              </v-col>

              <!-- Search Result Count -->
              <v-col cols="12" md="4" class="text-right">
                <span class="text-subtitle-1">
                  {{ filteredCases.length }} Results Found
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Loading State -->
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
        <div class="mt-3">Loading cases...</div>
      </v-col>
    </v-row>

    <!-- No Results State -->
    <v-row v-else-if="!loading && filteredCases.length === 0">
      <v-col cols="12">
        <v-card class="text-center pa-5">
          <v-icon size="64" color="grey lighten-1"
            >mdi-file-search-outline</v-icon
          >
          <h3 class="mt-4 mb-2">No Cases Found</h3>
          <p class="grey--text">Try adjusting your search filters</p>
          <v-btn
            color="primary"
            class="mt-3"
            @click="$router.push('/dashboard')"
          >
            Return to Dashboard
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <!-- Results Table -->
    <template v-else>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="tableSearch"
            append-icon="mdi-magnify"
            label="Filter results"
            single-line
            hide-details
            outlined
            dense
            class="mx-4"
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="filteredCases"
          :search="tableSearch"
          :items-per-page="10"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
          class="elevation-1"
          @click:row="(row) => navigateToCase(row.id)"
        >
          <!-- Case ID Column -->
          <template v-slot:[`item.id`]="{ item }">
            <span>
              {{ getFormattedCaseNumber(item) }}
            </span>
          </template>

          <!-- Vehicle No Column -->
          <template v-slot:[`item.vehicleNo`]="{ item }">
            {{ item.vehicleNo?.toUpperCase() }}
          </template>

          <!-- Status Column -->
          <template v-slot:[`item.fileStatus`]="{ item }">
            <v-chip
              small
              :color="getStatusColor(item.fileStatus)"
              text-color="white"
            >
              {{ getStatusName(item.fileStatus) }}
            </v-chip>
          </template>

          <!-- Date Column -->
          <template v-slot:[`item.dateOfAssign`]="{ item }">
            {{ formatDate(item.dateOfAssign) }}
          </template>

          <!-- Insurer Column -->
          <template v-slot:[`item.insurer`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ getInsurerName(item.insurer)?.substring(0, 20)
                  }}{{ getInsurerName(item.insurer)?.length > 20 ? "..." : "" }}
                </span>
              </template>
              <span>{{ getInsurerName(item.insurer) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </v-container>
</template>

<script>
// import CasefileDataService from "../services/CasefileDataService";
import DeptDataService from "../services/DeptDataService";
import BranchDataService from "../services/BranchDataService";
import StagesDataService from "../services/StageDataService";
import InsuranceDataService from "../services/InsDataService";
import moment from "moment";
import { mapState, mapGetters } from "vuex"; // Add Vuex mapping imports

export default {
  name: "AdvancedSearch",

  data() {
    return {
      loading: true,
      allCases: [],
      filteredCases: [],
      refType: [], // departments
      branch: [], // branches
      stages: [], // statuses
      insurers: [],
      tableSearch: "",
      headers: [
        { text: "AA REF", value: "id", sortable: true },
        { text: "Vehicle No", value: "vehicleNo", sortable: true },
        { text: "Status", value: "fileStatus", sortable: true },
        { text: "Insurer", value: "insurer", sortable: true },
        { text: "DOA", value: "dateOfAssign", sortable: true },
      ],
    };
  },

  computed: {
    ...mapState({
      storeLoading: (state) => state.casefiles.isLoading,
      allCasefiles: (state) => state.casefiles.casefiles,
    }),
    ...mapGetters("casefiles", ["getFilteredCasefiles"]),

    userFilteredCasefiles() {
      const user = this.$store.state.auth.user;
      if (!user) return [];

      return this.$store.getters["casefiles/getFilteredCasefiles"](
        user.roles,
        user.dept,
        user.branches,
        user.id
      );
    },

    departmentsForFilter() {
      if (!this.refType || this.refType.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user is an ADMIN, show all departments
      if (user.roles.includes("ADMIN")) {
        return [{ id: "all", name: "All Departments" }, ...this.refType];
      }

      // Otherwise, filter departments based on user's department access
      const userDepts = Array.isArray(user.dept) ? user.dept : [user.dept];

      const filteredDepts = this.refType.filter((dept) =>
        userDepts.some((userDept) => userDept === dept.id)
      );

      return [{ id: "all", name: "All Departments" }, ...filteredDepts];
    },

    branchesForFilter() {
      if (!this.branch || this.branch.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user is an ADMIN or MANAGER, show all branches
      if (user.roles.includes("ADMIN") || user.roles.includes("MANAGER")) {
        return [{ id: "all", name: "All Branches" }, ...this.branch];
      }

      // Otherwise, filter branches based on user's branch access
      const userBranches = Array.isArray(user.branches)
        ? user.branches
        : [user.branches];

      const filteredBranches = this.branch.filter((branch) =>
        userBranches.some((userBranch) => userBranch === branch.id)
      );

      return [{ id: "all", name: "All Branches" }, ...filteredBranches];
    },

    hasAnyFilters() {
      return (
        !!this.$route.query.keyword ||
        !!this.$route.query.dept ||
        !!this.$route.query.branch ||
        !!this.$route.query.status ||
        !!this.$route.query.insurer
      );
    },

    searchQuery() {
      return {
        keyword: this.$route.query.keyword || "",
        dept: this.$route.query.dept || null,
        branch: this.$route.query.branch || null,
        status: this.$route.query.status || null,
        insurer: this.$route.query.insurer || null,
      };
    },
  },

  watch: {
    "$route.query": {
      handler() {
        this.applyFilters();
      },
      deep: true,
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;

      try {
        // First, ensure casefiles are loaded in the Vuex store
        await this.$store.dispatch("casefiles/fetchCasefiles");

        // Then fetch the reference data in parallel
        const [deptResponse, branchResponse, stageResponse, insurerResponse] =
          await Promise.all([
            DeptDataService.getAll(),
            BranchDataService.getAll(),
            StagesDataService.getAll(),
            InsuranceDataService.getAll(),
          ]);

        this.refType = deptResponse.data;
        this.branch = branchResponse.data;
        this.stages = stageResponse.data;
        this.insurers = insurerResponse.data;

        // Apply search filters on the user-filtered casefiles from the store
        this.applyFilters();
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$store.dispatch("alerts/setAlert", {
          text: "Error loading search results",
          type: "error",
        });
        this.filteredCases = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters() {
      // First get only the casefiles the user is permitted to see
      const permittedCasefiles = this.userFilteredCasefiles;
      if (!permittedCasefiles || permittedCasefiles.length === 0) {
        this.filteredCases = [];
        return;
      }

      const { keyword, dept, branch, status, insurer } = this.searchQuery;

      this.filteredCases = permittedCasefiles.filter((item) => {
        // Filter by department if specified and not "all"
        if (dept && dept !== "all") {
          // Convert both values to strings for comparison
          const itemRefType = String(item.refType);
          const queryDept = String(dept);
          if (itemRefType !== queryDept) {
            return false;
          }
        }

        // Filter by branch if specified and not "all"
        if (branch && branch !== "all") {
          // Convert both values to strings for comparison
          const itemBranch = String(item.branch);
          const queryBranch = String(branch);
          if (itemBranch !== queryBranch) {
            return false;
          }
        }

        // Filter by status if specified and not "all" and item.fileStatus !== status
        if (status && status !== "all" && item.fileStatus !== status) {
          return false;
        }

        // Filter by insurer if specified
        if (insurer) {
          const itemInsurer = String(item.insurer);
          const queryInsurer = String(insurer);
          if (itemInsurer !== queryInsurer) {
            return false;
          }
        }

        // Filter by keyword
        if (keyword) {
          const keywordLower = keyword.toLowerCase();
          const searchableFields = [
            item.vehicleNo,
            item.claimNo,
            item.personInvolved,
            item.insComment,
            String(item.id),
          ].filter((field) => field != null);

          return searchableFields.some((field) =>
            String(field).toLowerCase().includes(keywordLower)
          );
        }

        return true;
      });
    },

    getDepartmentName(id) {
      if (!id || id === "all") return "All Departments";
      const dept = this.refType.find(
        (d) => d.id === parseInt(id) || d.id === id
      );
      return dept ? dept.name : `Dept ${id}`;
    },

    getBranchName(id) {
      if (!id || id === "all") return "All Branches";
      const br = this.branch.find((b) => b.id === parseInt(id) || b.id === id);
      return br ? br.name : `Branch ${id}`;
    },

    getStatusName(code) {
      if (!code || code === "all") return "All Statuses";
      const stage = this.stages.find((s) => s.stageCode === code);
      return stage ? stage.name : code;
    },

    getStatusColor(status) {
      const colors = {
        NEW: "green",
        PEND: "amber",
        INVE: "blue",
        PEDI: "purple",
        EDAC: "indigo",
        EDI: "pink",
        PAPP: "cyan",
        HOLD: "orange",
        FOIN: "teal",
        DISP: "light-blue",
        CLO: "grey",
        CANC: "red",
      };

      return colors[status] || "grey";
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    navigateToCase(id) {
      this.$router.push({
        name: "vuexFile",
        params: { id },
      });
    },

    getFormattedCaseNumber(item) {
      if (!item) return "N/A";

      const dept = this.refType.find((d) => d.id === item.refType);
      const br = this.branch.find((b) => b.id === item.branch);

      let result = `AA-${item.id}`;

      if (dept) {
        result = `AA/${dept.name}/${item.id}`;
      }

      if (br && br.brCode) {
        result += `/${br.brCode}`;
      }

      return result;
    },

    getInsurerName(id) {
      if (!id) return "N/A";
      const insurer = this.insurers.find((ins) => ins.id === id);
      return insurer ? insurer.name : `Insurer ${id}`;
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.v-chip {
  font-weight: 500;
}
.v-data-table >>> tbody tr {
  cursor: pointer;
}
</style>
