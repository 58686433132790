const config = {
  development: {
    baseURL: "http://localhost:8080/api",
    docsURL: "http://localhost:8080/docs", // Add docs URL for development
  },
  production: {
    baseURL: "/api",
    docsURL: "/docs", // Add docs URL for production
  },
};

const environment = process.env.NODE_ENV || "development";
export const apiConfig = config[environment];
