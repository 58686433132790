import axios from "axios";
import { apiConfig } from "./config/api.config";

const http = axios.create({
  baseURL: apiConfig.baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

// Add request interceptor for debugging
// http.interceptors.request.use(
//   (config) => {
//     console.log(`API Request to: ${config.baseURL}${config.url}`);
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default http;
