import store from "@/store";

export function beforeRouteEnter(to, from, next) {
  const currentUser = store.state.auth.user;

  if (!currentUser) {
    next("/login");
    return;
  }

  if (to.name === "vuexFile") {
    // Force refresh when viewing file details
    store
      .dispatch("casefiles/fetchCasefiles", currentUser.id)
      .then(() => next())
      .catch(() => next(false));
    return;
  }

  if (to.name === "home" && from.name === "vuexFile") {
    // Only refresh if cache is stale or user changed
    if (store.getters["casefiles/needsRefresh"]) {
      store.dispatch("casefiles/fetchCasefiles", currentUser.id);
    }
  }
  next();
}
