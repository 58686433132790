<template>
  <v-container fluid>
    <!-- Replace existing snackbar with new styled version -->
    <v-snackbar
      v-model="processSnackbar.show"
      :timeout="processSnackbar.timeout"
      :color="processSnackbar.color"
      top
      :style="{ left: '50%', transform: 'translateX(-50%)' }"
      class="notification-snackbar"
    >
      <v-alert
        :value="true"
        :color="processSnackbar.color"
        :icon="getSnackbarIcon"
        class="mb-0"
        dense
        outlined
        text
      >
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="processSnackbar.loading"
            indeterminate
            size="20"
            width="2"
            :color="processSnackbar.color"
            class="mr-3"
          ></v-progress-circular>
          <span :class="`${processSnackbar.color}--text`">{{
            processSnackbar.text
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!processSnackbar.loading"
            icon
            x-small
            :color="processSnackbar.color"
            @click="processSnackbar.show = false"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="sortedCasefiles"
          :item-class="itemClass"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [10, 20, 30, 40, 50, 100],
          }"
          :items-per-page="30"
          :search="search"
          class="elevation-1"
          mobile-breakpoint="1100"
          @click:row="viewItem"
          style="cursor: pointer"
        >
          <template v-slot:top>
            <v-card flat class="mb-1">
              <v-row no-gutters>
                <!-- Action Buttons Section - 4 columns on md+ screens -->
                <v-col cols="12" md="4" class="pa-2">
                  <v-card
                    flat
                    class="action-buttons-card h-100 d-flex flex-column justify-center"
                  >
                    <v-card-text
                      class="pa-2 d-flex flex-wrap justify-center align-center"
                    >
                      <!-- Add Case Button -->
                      <v-dialog
                        v-model="addDialog"
                        max-width="600px"
                        persistent
                      >
                        <template v-slot:activator="{ on: dialog, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mx-2 my-1 action-btn"
                            v-bind="attrs"
                            v-on="{ ...dialog }"
                            :disabled="addCasefileDisabled"
                          >
                            <v-icon left>mdi-plus</v-icon>
                            Add Case
                          </v-btn>
                        </template>

                        <!-- Add your existing case dialog content here -->
                        <v-card tile>
                          <v-card-title
                            class="justify-center text-h5 grey lighten-2"
                          >
                            <span class="text-h5">ADD NEW CASE</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-card width="100%">
                                  <v-card-title>Department</v-card-title>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="newItem.refType"
                                      :items="refType"
                                      item-text="name"
                                      item-value="id"
                                      :menu-props="{ maxHeight: '400' }"
                                      hint="Select Department"
                                      persistent-hint
                                      label="Department"
                                    >
                                      <template #label>
                                        <span class="red--text"
                                          ><strong>* </strong></span
                                        >Department
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="newItem.subRefType"
                                      :items="filtersubRefType(newItem.refType)"
                                      item-text="subCode"
                                      item-value="id"
                                      no-data-text="There is no file-Classification option for selected department above"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="File-Classification"
                                      hint="Select File-Classification"
                                      persistent-hint
                                    ></v-select>
                                  </v-col>
                                </v-card>
                                <v-col cols="12">
                                  <v-select
                                    v-model="newItem.branch"
                                    :items="branches"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{ maxHeight: '400' }"
                                    hint="Set the casefile branch"
                                    persistent-hint
                                    label="Branch"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="newItem.insurer"
                                    :items="insurer"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{ maxHeight: '400' }"
                                    hint="Set the casefile insurer"
                                    persistent-hint
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Insurer
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    v-model="newItem.handler"
                                    :items="filterHandler(newItem.insurer)"
                                    item-text="name"
                                    item-value="id"
                                    no-data-text="There is no handler option for selected insurer above"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Claim Handler"
                                    hint="Select Handler"
                                    persistent-hint
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="newItem.claimNo"
                                    item-text="Insurer's Reference"
                                    label="Insurers Reference Number"
                                    :rules="[
                                      (v) =>
                                        !!v || 'Insurers reference is required',
                                    ]"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Insurers Reference
                                    </template></v-text-field
                                  >
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <template>
                                    <div>
                                      <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="ComputedDateOfAssignAdd"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                          >
                                            <template #label>
                                              <span class="red--text"
                                                ><strong>* </strong></span
                                              >Date Of Assignment
                                            </template>
                                          </v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="newItem.dateOfAssign"
                                          @change="menu = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </template>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="newItem.vehicleNo"
                                    label="Vehicle Number"
                                    hint="Vehicle Number"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <template>
                                    <div>
                                      <v-menu
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        max-width="290"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="ComputedDateOfLossAdd"
                                            persistent-hint
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                          >
                                            <template #label>
                                              <span class="red--text"
                                                ><strong>* </strong></span
                                              >Date Of Loss
                                            </template>
                                          </v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="newItem.dateOfLoss"
                                          @change="menu1 = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </template>
                                </v-col>
                                <v-card width="100%">
                                  <v-card-title>Place Of Loss</v-card-title>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="newItem.stateOfLoss"
                                      :items="state"
                                      item-text="name"
                                      item-value="id"
                                      :menu-props="{ maxHeight: '400' }"
                                      hint="Select State"
                                      persistent-hint
                                      label="State"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="newItem.placeOfLoss"
                                      :items="
                                        filterRegions(newItem.stateOfLoss)
                                      "
                                      item-text="name"
                                      item-value="name"
                                      no-data-text="There is no district option for selected state above"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Area Of Loss"
                                      hint="Select District"
                                      persistent-hint
                                    ></v-select>
                                  </v-col>
                                </v-card>

                                <v-col cols="12">
                                  <v-text-field
                                    v-model="newItem.insuredName"
                                    label="Insured Name"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    v-model="newItem.insComment"
                                    label="Insurers Comment"
                                  >
                                    <template #label>
                                      <span class="red--text"
                                        ><strong>* </strong></span
                                      >Insurers Comment
                                    </template>
                                  </v-textarea>
                                </v-col>

                                <!-- <v-col cols="12" sm="12" md="12">
                                  <v-select
                                    v-model="newItem.fileStatus"
                                    :items="stages"
                                    item-text="name"
                                    item-value="stageCode"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="FILE STATUS"
                                    hint="Set File Status - Will be removed on Production version"
                                    persistent-hint
                                  ></v-select>
                                </v-col> -->

                                <v-col cols="12" sm="6" md="6">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    outlined
                                    small
                                    tile
                                    @click="closeAddDialog"
                                    class="mt-3 mr-2"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    :disabled="isAddFileButtonDisabled"
                                    class="success mt-3"
                                    small
                                    tile
                                    @click="addCasefile"
                                    >Submit</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-dialog>

                      <!-- Archive Button -->
                      <v-btn
                        color="success"
                        dark
                        class="mx-2 my-1 action-btn"
                        to="/archive"
                      >
                        <v-icon left>mdi-archive-alert</v-icon>
                        Archive
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Advanced Search Section - 8 columns on md+ screens -->
                <v-col cols="12" md="8" class="pa-2">
                  <v-card flat class="search-card h-100">
                    <v-card-title class="search-card-title py-1 px-4">
                      <v-icon small color="primary" class="mr-2"
                        >mdi-magnify</v-icon
                      >
                      <span class="text-subtitle-2">Advanced Search</span>
                    </v-card-title>

                    <v-card-text class="pa-2">
                      <v-row dense>
                        <v-col cols="12" sm="6" md="3" class="py-1">
                          <v-text-field
                            v-model="advancedSearch.keyword"
                            label="Search by keyword"
                            placeholder="Case ID, Vehicle No, etc"
                            prepend-inner-icon="mdi-text-search"
                            clearable
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" class="py-1">
                          <v-select
                            v-model="advancedSearch.dept"
                            :items="departmentsForFilter"
                            item-text="name"
                            item-value="id"
                            label="Department"
                            outlined
                            dense
                            clearable
                            hide-details
                            prepend-inner-icon="mdi-domain"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" class="py-1">
                          <v-select
                            v-model="advancedSearch.branch"
                            :items="branchesForFilter"
                            item-text="name"
                            item-value="id"
                            label="Branch"
                            outlined
                            dense
                            clearable
                            hide-details
                            prepend-inner-icon="mdi-office-building"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" class="py-1">
                          <v-autocomplete
                            v-model="advancedSearch.insurer"
                            :items="insurersForFilter"
                            item-text="name"
                            item-value="id"
                            label="Insurer"
                            outlined
                            dense
                            clearable
                            hide-details
                            prepend-inner-icon="mdi-shield-account"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" sm="6" md="3" class="py-1">
                          <v-select
                            v-model="advancedSearch.status"
                            :items="stagesForFilter"
                            item-text="name"
                            item-value="stageCode"
                            label="Status"
                            outlined
                            dense
                            clearable
                            hide-details
                            prepend-inner-icon="mdi-flag"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="9"
                          class="d-flex justify-end pt-2 pb-0"
                        >
                          <v-btn
                            color="primary"
                            class="mr-2"
                            @click="performAdvancedSearch"
                            :loading="searchLoading"
                            :disabled="!isAdvancedSearchValid"
                            small
                          >
                            <v-icon left small>mdi-magnify</v-icon>
                            Search
                          </v-btn>
                          <v-btn text small @click="resetAdvancedSearch"
                            >Clear</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </template>

          <template slot="item.numbering" slot-scope="props">
            {{ props.index + 1 }}
          </template>

          <template v-slot:[`item.insurer`]="{ item }">
            <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
              <div class="text-truncate" style="max-width: 100px">
                {{ ins.name }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.vehicleNo`]="{ item }">
            <span v-if="item.vehicleNo">{{
              item.vehicleNo.toUpperCase()
            }}</span>
            <span v-else>TBA</span>
          </template>

          <template v-slot:[`item.id`]="{ item }">
            AA
            <span
              v-for="(dept, index) in filterDept(item.refType)"
              :key="index"
            >
              /{{ dept.name }}
              <span
                v-for="(subDept, index) in filterSubDept(item.subRefType)"
                :key="index"
              >
                /{{ subDept.subCode }}
              </span>
            </span>
            /{{ item.id }}/{{ yearOnly(item.dateOfAssign) }}
            <span
              v-for="branch in filterBranches(item.branch)"
              :key="branch.id"
            >
              /{{ branch.brCode }}
            </span>
          </template>

          <template v-slot:[`item.dateOfAssign`]="{ item }">
            {{ formatDate(item.dateOfAssign) }}
          </template>

          <template v-slot:[`item.days`]="{ item }">
            {{
              dateDiff(moment(item.dateOfAssign).format(), moment().format())
            }}
          </template>

          <template v-slot:[`item.adjuster`]="{ item }">
            <span v-if="item.adjuster">
              <div
                v-for="adjuster in filterUser(item.adjuster)"
                :key="adjuster.id"
              >
                {{ adjuster.username }}
              </div>
            </span>
            <span v-else>TBA</span>
          </template>

          <template v-slot:[`item.fileStatus`]="{ item }">
            <v-tooltip left transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.flag === 1"
                  small
                  color="green lighten-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-shield-check
                </v-icon>
                <v-icon
                  v-if="item.flag === 2"
                  small
                  color="orange lighten-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-shield-half-full
                </v-icon>
                <v-icon
                  v-if="item.flag === 3"
                  small
                  color="red darken-5"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-shield-alert-outline
                </v-icon>
              </template>
              <span v-if="item.flag === 1">GENUINE</span>
              <span v-if="item.flag === 2">SUSPECTED FRAUD</span>
              <span v-if="item.flag === 3">FRAUD</span>
            </v-tooltip>

            <span
              v-for="allStatus in filterStages(item.fileStatus)"
              :key="allStatus.id"
            >
              <v-chip label outlined block x-small>
                {{ allStatus.name }}
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import DeptDataService from "../services/DeptDataService";
import SubRefTypeDataService from "../services/SubDeptDataService";
import InsDataService from "../services/InsDataService";
import BranchDataService from "../services/BranchDataService";
import StateDataService from "../services/StateDataService";
import RegionDataService from "../services/RegionDataService";
import MemberDataService from "../services/MemberDataService";
import InsHandlerDataService from "../services/HandlerDataService";
import StagesDataService from "../services/StageDataService";
import TatChartDataService from "../services/TatChartDataService";
import MailDataService from "../services/MailDataService";
import CasefileDataService from "../services/CasefileDataService";

export default {
  name: "CaseList",

  data: () => ({
    search: "",
    refType: [],
    subRefTypes: [],
    insurer: [],
    members: [],
    stages: [],
    tatCharts: [],
    addDialog: false,
    management: false,
    addcase: false,
    menu: false,
    menu1: false,
    newItem: {
      id: null,
      refType: null,
      subRefType: null,
      branch: null,
      insurer: null,
      handler: null,
      claimNo: "",
      dateOfAssign: "",
      vehicleNo: "",
      dateOfLoss: "",
      stateOfLoss: null,
      placeOfLoss: "",
      insuredName: "",
      insComment: "",
      createdBy: null,
      fileStatus: "",
      dateStartInv: "",
    },
    sortBy: ["days"],
    sortDesc: [true],
    branches: [], // This is the original branches property
    state: [],
    regions: [],
    handlers: [],

    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      {
        text: "VEHICLE NO.",
        align: "center",
        value: "vehicleNo",
        sortable: false,
      },
      {
        text: "AA REF",
        align: "start",
        value: "id",
        sortable: false,
      },
      {
        text: "INSURER",
        align: "center",
        value: "insurer",
        sortable: false,
      },
      {
        text: "DOA",
        align: "start",
        sortable: false,
        value: "dateOfAssign",
      },
      {
        text: "DAYS",
        align: "start",
        sortable: false,
        value: "days",
      },
      {
        text: "ADJUSTER",
        align: "start",
        sortable: false,
        value: "adjuster",
      },
      {
        text: "STATUS",
        align: "center",
        value: "fileStatus",
        sortable: false,
      },
    ],
    showLoadingSnackbar: false, // Add this property
    isLoading: true,
    processSnackbar: {
      show: false,
      text: "",
      color: "info",
      timeout: 3000,
      loading: false,
    },
    searchLoading: false,
    advancedSearch: {
      keyword: "",
      dept: null,
      branch: null,
      status: null,
      insurer: null, // Add this line
    },
    // Remove the duplicate "branches" declaration
    departments: [], // Keep only this line
  }),

  computed: {
    ...mapState({
      storeLoading: (state) => state.casefiles.isLoading,
      allCasefiles: (state) => state.casefiles.casefiles,
    }),
    ...mapGetters("casefiles", ["getFilteredCasefiles"]),

    filteredCasefiles() {
      const user = this.$store.state.auth.user;
      if (!user) return [];

      return this.$store.getters["casefiles/getFilteredCasefiles"](
        user.roles,
        user.dept,
        user.branches,
        user.id
      );
    },

    ComputedDateOfAssignAdd() {
      return this.formatDateMethod(this.newItem.dateOfAssign);
    },
    ComputedDateOfLossAdd() {
      return this.formatDateMethod(this.newItem.dateOfLoss);
    },

    sortedCasefiles() {
      if (!this.filteredCasefiles.length) return [];

      return [...this.filteredCasefiles].sort((a, b) => {
        const aValue =
          this.dateDiff(moment(a.dateOfAssign).format(), moment().format()) ||
          0;
        const bValue =
          this.dateDiff(moment(b.dateOfAssign).format(), moment().format()) ||
          0;
        return this.sortDesc[0] ? bValue - aValue : aValue - bValue;
      });
    },

    addCasefileDisabled() {
      return !this.addcase;
    },
    isAddFileButtonDisabled() {
      return !(
        this.newItem.refType &&
        this.newItem.insurer &&
        this.newItem.claimNo &&
        this.newItem.dateOfAssign &&
        this.newItem.dateOfLoss &&
        this.newItem.insComment
      );
    },
    getSnackbarIcon() {
      switch (this.processSnackbar.color) {
        case "success":
          return "mdi-check-circle";
        case "error":
          return "mdi-alert-circle";
        case "warning":
          return "mdi-alert";
        default:
          return "mdi-information";
      }
    },
    departmentsForFilter() {
      if (!this.refType || this.refType.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user has admin, branch manager, or clerk role, show all departments
      if (
        user.roles.some((role) => ["ADMIN", "MANAGER", "CLERK"].includes(role))
      ) {
        return [{ id: "all", name: "All Departments" }, ...this.refType];
      }

      // Otherwise, filter departments based on user's department access
      const userDepts = Array.isArray(user.dept) ? user.dept : [user.dept];

      const filteredDepts = this.refType.filter((dept) =>
        userDepts.some((userDept) => userDept === dept.id)
      );

      return [{ id: "all", name: "All Departments" }, ...filteredDepts];
    },

    branchesForFilter() {
      if (!this.branches || this.branches.length === 0) return [];

      const user = this.$store.state.auth.user;
      if (!user) return [];

      // If user has admin, branch manager, manager, or clerk role, show all branches
      if (
        user.roles.some((role) => ["ADMIN", "MANAGER", "CLERK"].includes(role))
      ) {
        return [{ id: "all", name: "All Branches" }, ...this.branches];
      }

      // Otherwise, filter branches based on user's branch access
      const userBranches = Array.isArray(user.branches)
        ? user.branches
        : [user.branches];

      const filteredBranches = this.branches.filter((branch) =>
        userBranches.some((userBranch) => userBranch === branch.id)
      );

      return [{ id: "all", name: "All Branches" }, ...filteredBranches];
    },

    stagesForFilter() {
      if (!this.stages || this.stages.length === 0) return [];
      const filteredStages = this.stages.filter(
        (stage) => stage.stageCode && stage.name
      );
      return [{ stageCode: "all", name: "All Statuses" }, ...filteredStages];
    },

    insurersForFilter() {
      if (!this.insurer || this.insurer.length === 0) return [];
      return this.insurer;
    },

    isAdvancedSearchValid() {
      return (
        this.advancedSearch.keyword ||
        this.advancedSearch.dept ||
        this.advancedSearch.branch ||
        this.advancedSearch.status ||
        this.advancedSearch.insurer // Add this line
      );
    },
  },

  methods: {
    moment(date) {
      return moment(date);
    },

    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },

    formatDateMethod(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY");
    },

    yearOnly(value) {
      return moment(value).format("YY");
    },

    dateDiff(startDateString, endDateString) {
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.floor(duration.asDays());
    },

    filterDept(item) {
      return this.refType.filter((dept) => dept.id === item);
    },

    filterSubDept(item) {
      return this.subRefTypes.filter((subDept) => subDept.id === item);
    },

    filtersubRefType(item) {
      return this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === item
      );
    },

    filterHandler(item) {
      return this.handlers.filter((handler) => handler.insId === item);
    },

    filterBranches(item) {
      return this.branches.filter((branch) => branch.id === item);
    },

    filterBranchesEmail(item) {
      if (!item) return "TBA";
      const filteredbranch = this.branches.filter(
        (branch) => branch.id === item
      );

      return filteredbranch[0].name;
    },

    filterVehicleNo(item) {
      if (!item) return "TBA";
      return item.toLowerCase().replace(/\s/g, "");
    },

    filterIns(item) {
      return this.insurer.filter((ins) => ins.id === item);
    },

    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },

    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },

    filterRegions(item) {
      return this.regions.filter((region) => region.branchId === item);
    },

    viewItem(item) {
      this.$router.push({
        name: "vuexFile",
        params: { id: item.id },
      });
    },

    getTatValues(insurerId, subRefTypeId) {
      if (!insurerId || !subRefTypeId) return null;
      const filteredTat = this.tatCharts.find(
        (tat) => tat.insId === insurerId && tat.subDeptId === subRefTypeId
      );
      return filteredTat
        ? {
            tatAlert: parseInt(filteredTat.tatAlert),
            tatMax: parseInt(filteredTat.tatMax),
          }
        : null;
    },

    getStatusClass(days, tatAlert, tatMax) {
      const daysNum = Number(days);
      const alertNum = Number(tatAlert);
      const maxNum = Number(tatMax);

      if (daysNum >= maxNum) return "tat-overdue-force";
      if (daysNum >= alertNum) return "tat-alert-force";
      return "";
    },

    itemClass(item) {
      if (!item || !item.dateOfAssign) return "";
      const days = this.dateDiff(
        moment(item.dateOfAssign).format(),
        moment().format()
      );

      const tatValues = this.getTatValues(item.insurer, item.subRefType);
      if (!tatValues) return "";

      return this.getStatusClass(days, tatValues.tatAlert, tatValues.tatMax);
    },

    checkManagement() {
      if (
        this.$store.state.auth.user.roles.includes(`ADMIN`) ||
        this.$store.state.auth.user.roles.includes(`MANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHMANAGER`) ||
        this.$store.state.auth.user.roles.includes(`BRANCHCLERK`)
      ) {
        this.management = true;
      }
    },

    checkAddCase() {
      this.addcase = this.$store.state.auth.user.roles.some((role) =>
        ["ADMIN", "MANAGER", "CLERK", "BRANCHMANAGER", "BRANCHCLERK"].includes(
          role
        )
      );
    },

    closeAddDialog() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.newItem = Object.assign({});
      });
    },

    addCasefile() {
      var data = {
        refType: this.newItem.refType,
        subRefType: this.newItem.subRefType,
        branch: this.newItem.branch,
        insurer: this.newItem.insurer,
        handler: this.newItem.handler,
        claimNo: this.newItem.claimNo,
        dateOfAssign: this.newItem.dateOfAssign,
        vehicleNo: this.filterVehicleNo(this.newItem.vehicleNo),
        dateOfLoss: this.newItem.dateOfLoss,
        stateOfLoss: this.newItem.stateOfLoss,
        placeOfLoss: this.newItem.placeOfLoss,
        insuredName: this.newItem.insuredName,
        insComment: this.newItem.insComment,
        createdBy: this.$store.state.auth.user.id,
        flag: 1,
        fileStatus: `NEW`,
        caseFrom: `ORGANIC`,
      };

      var dataEmail = {
        refType:
          this.refType.find((dept) => dept.id === this.newItem.refType)?.name ||
          "N/A",
        subRefType:
          this.subRefTypes.find((sub) => sub.id === this.newItem.subRefType)
            ?.subCodeDesc || "N/A",
        branch: this.filterBranchesEmail(this.newItem.branch),
        insurer:
          this.insurer.find((ins) => ins.id === this.newItem.insurer)?.name ||
          "N/A",
        handler: this.newItem.handler,
        claimNo: this.newItem.claimNo,
        dateOfAssign: this.newItem.dateOfAssign,
        vehicleNo: this.newItem.vehicleNo
          ? this.newItem.vehicleNo.toLowerCase().replace(/\s/g, "")
          : "TBA",
        dateOfLoss: this.newItem.dateOfLoss,
        stateOfLoss: this.newItem.stateOfLoss,
        placeOfLoss: this.newItem.placeOfLoss,
        insuredName: this.newItem.insuredName,
        insComment: this.newItem.insComment,
        createdBy: this.$store.state.auth.user.id,
        flag: 1,
        fileStatus: `NEW`,
        caseFrom: `ORGANIC`,
      };

      CasefileDataService.create(data)
        .then((response) => {
          this.closeAddDialog();
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text: response.data.message || "Success",
          });
        })
        .then(() => {
          MailDataService.mailData(dataEmail)
            .then((response) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "success",
                text: response.data.message || "Email sent",
              });
            })
            .catch((e) => {
              this.$store.dispatch("setSnackbar", {
                showing: true,
                color: "error",
                text: e.toString(),
              });
            });
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            text: e.response?.data?.message || e.message,
          });
        });

      this.closeAddDialog();
    },

    showProcessUpdate(text, isLoading = true, color = "info") {
      this.processSnackbar = {
        show: true,
        text,
        color,
        timeout: isLoading ? -1 : 3000,
        loading: isLoading,
      };
    },

    async loadData() {
      this.showProcessUpdate("Loading data...");
      try {
        const [
          refType,
          subRefTypes,
          branches,
          state,
          regions,
          insurer,
          members,
          handlers,
          stages,
          tatCharts,
        ] = await Promise.all([
          DeptDataService.getAll(),
          SubRefTypeDataService.getAll(),
          BranchDataService.getAll(),
          StateDataService.getAll(),
          RegionDataService.getAll(),
          InsDataService.getAll(),
          MemberDataService.getAll(),
          InsHandlerDataService.getAll(),
          StagesDataService.getAll(),
          TatChartDataService.getAll(),
        ]);

        this.refType = refType.data;
        this.subRefTypes = subRefTypes.data;
        this.branches = branches.data;
        this.state = state.data;
        this.regions = regions.data;
        this.insurer = insurer.data;
        this.members = members.data;
        this.handlers = handlers.data;
        this.stages = stages.data;
        this.tatCharts = tatCharts.data;

        this.showProcessUpdate("Data loaded successfully", false, "success");
      } catch (error) {
        console.error("Error loading data:", error);
        this.showProcessUpdate("Error loading data", false, "error");
      }
    },

    openAdvancedSearch() {
      this.$router.push({ name: "advancedSearch" });
    },
    async performAdvancedSearch() {
      if (!this.isAdvancedSearchValid) return;

      this.searchLoading = true;

      try {
        const query = {};
        // Create a description of the search for recent searches
        const searchParts = []; // Move the declaration to the top of the function

        if (this.advancedSearch.keyword) {
          query.keyword = this.advancedSearch.keyword;
          searchParts.push(`"${this.advancedSearch.keyword}"`);
        }

        if (this.advancedSearch.dept && this.advancedSearch.dept !== "all") {
          query.dept = this.advancedSearch.dept;
          const deptName =
            this.refType.find((d) => d.id === this.advancedSearch.dept)?.name ||
            "Dept";
          searchParts.push(deptName);
        }

        if (
          this.advancedSearch.branch &&
          this.advancedSearch.branch !== "all"
        ) {
          query.branch = this.advancedSearch.branch;
          const branchName =
            this.branches.find((b) => b.id === this.advancedSearch.branch)
              ?.name || "Branch";
          searchParts.push(branchName);
        }

        if (
          this.advancedSearch.status &&
          this.advancedSearch.status !== "all"
        ) {
          query.status = this.advancedSearch.status;
          const statusName =
            this.stages.find((s) => s.stageCode === this.advancedSearch.status)
              ?.name || this.advancedSearch.status;
          searchParts.push(`${statusName}`);
        }

        // Add insurer to query params if selected
        if (this.advancedSearch.insurer) {
          query.insurer = this.advancedSearch.insurer;
          const insurerName =
            this.insurer.find((i) => i.id === this.advancedSearch.insurer)
              ?.name || "Insurer";
          searchParts.push(insurerName);
        }

        // Save to recent searches if that functionality exists
        if (this.addToRecentSearches) {
          this.addToRecentSearches(
            searchParts.join(", ") || "Advanced Search",
            {
              type: "advanced",
              params: { ...query },
            }
          );
        }

        // Navigate to advanced search with params
        await this.$router.push({
          name: "advancedSearch",
          query,
        });

        // Hide the advanced search panel after search is done
        this.showAdvancedSearch = false;
      } catch (error) {
        console.error("Search error:", error);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          text: error.message || "Error performing search",
          color: "error",
        });
      } finally {
        this.searchLoading = false;
      }
    },

    resetAdvancedSearch() {
      this.advancedSearch = {
        keyword: "",
        dept: null,
        branch: null,
        status: null,
        insurer: null, // Add this line
      };
    },

    // Add this method if you track recent searches
    addToRecentSearches(text, data) {
      try {
        // Load existing searches
        let searches = [];
        const savedSearches = localStorage.getItem("recentSearches");
        if (savedSearches) {
          searches = JSON.parse(savedSearches);
        }

        // Add new search
        searches.unshift({ text, data });

        // Keep only the latest 10 searches
        searches = searches.slice(0, 10);

        // Save back to localStorage
        localStorage.setItem("recentSearches", JSON.stringify(searches));
      } catch (error) {
        console.error("Error saving recent searches:", error);
      }
    },

    // If you need to modify your existing retrieveData method to get additional data
    async loadFilterData() {
      try {
        // If not already loaded in loadData()
        if (!this.stages || this.stages.length === 0) {
          const stagesResponse = await StagesDataService.getAll();
          this.stages = stagesResponse.data;
        }

        if (!this.refType || this.refType.length === 0) {
          const deptResponse = await DeptDataService.getAll();
          this.refType = deptResponse.data;
        }

        if (!this.branches || this.branches.length === 0) {
          // Fix: Update branch data to this.branches (maintaining consistency)
          const branchResponse = await BranchDataService.getAll();
          this.branches = branchResponse.data;
        }
      } catch (error) {
        console.error("Error loading filter data:", error);
      }
    },
  },

  async mounted() {
    this.showProcessUpdate("Loading data...");
    try {
      await this.$store.dispatch("casefiles/fetchCasefiles");
      await this.loadData();
      this.checkManagement();
      this.checkAddCase();
    } catch (error) {
      this.showProcessUpdate("Error loading page", false, "error");
      console.error("Error in mounted:", error);
    }
    // Load filter data if needed
    await this.loadFilterData();
  },
};
</script>

<style>
.tat-alert-force {
  background-color: rgb(255, 241, 230) !important;
  border-left: 3px solid orange !important;
}

.tat-overdue-force {
  background-color: rgb(248, 204, 204) !important;
  border-left: 3px solid red !important;
}

.v-data-table >>> tbody tr.tat-alert-force:hover {
  background-color: rgb(255, 231, 220) !important;
}

.v-data-table >>> tbody tr.tat-overdue-force:hover {
  background-color: rgb(238, 194, 194) !important;
}

.v-snackbar {
  pointer-events: none;
}

.v-snackbar__wrapper {
  pointer-events: auto;
  cursor: pointer;
}

/* Add these new styles */
.quick-find-section {
  transition: all 0.3s ease;
}

.quick-find-input {
  transition: width 0.3s ease;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .quick-find-section {
    width: 100%;
    margin-top: 8px;
  }

  .v-toolbar__content {
    flex-wrap: wrap;
  }

  .quick-find-input {
    max-width: none !important;
  }
}

/* Update the styles for the search section */
.search-section {
  width: 100%;
  transition: all 0.3s ease;
}

/* Keep the mobile responsiveness but for the new search layout */
@media (max-width: 768px) {
  .search-section {
    margin-bottom: 8px;
  }

  .v-toolbar__content {
    flex-wrap: wrap;
    height: auto !important;
    padding-bottom: 16px;
  }

  .v-btn {
    margin: 4px;
  }
}

/* Add these new styles for the enhanced layout */
.action-buttons-card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.search-card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.search-card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.01);
}

.action-btn {
  min-width: 130px;
  height: 36px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 960px) {
  .action-buttons-card {
    margin-bottom: 16px;
  }

  .action-btn {
    width: calc(50% - 16px);
    margin: 8px !important;
  }
}

@media (max-width: 600px) {
  .action-btn {
    width: 100%;
    margin: 4px 0 !important;
  }

  .v-card-title.search-card-title {
    padding: 8px 12px;
    font-size: 14px;
  }

  .v-card-text {
    padding: 8px !important;
  }

  .v-btn {
    margin-bottom: 8px;
  }
}

/* Make search inputs more consistent */
.v-input__slot {
  min-height: 40px !important;
}

/* Ensure whole card is clickable in data table */
.v-data-table >>> tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

/* Card height consistency */
.h-100 {
  height: 100%;
}
</style>
