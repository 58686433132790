import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Archive from "./views/Archive.vue";
import Listing from "./views/Listing.vue";
import Find from "./views/QuickFind.vue";
import Forgot from "./components/user/forgot";
import Reset from "./components/user/reset";
import Activate from "./components/user/activate";
import Login from "./views/Login.vue";
import NotFound from "./components/NotFound";
import { beforeRouteEnter } from "./router/navigationGuard";
import AdvancedSearch from "./views/AdvancedSearch.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    // Home
    {
      path: "/",
      meta: {
        title: "Welcome",
      },
      component: Home,
      title: Home,
    },
    {
      path: "/home",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: {
        title: "Home",
        keepAlive: true,
        cacheTimeout: 300000, // 5 minutes
      },
      beforeEnter: beforeRouteEnter,
    },
    {
      path: "/archive",
      meta: {
        title: "Archive",
      },
      component: Archive,
      title: Archive,
    },
    // Listing
    {
      path: "/listing",
      meta: {
        title: "Listing",
      },
      component: Listing,
      title: Listing,
    },

    // Quickfind
    {
      path: "/find",
      name: "find",
      meta: {
        title: "Quick Find",
      },
      component: Find,
    },
    // Login
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    // Forgot
    {
      path: "/forgot",
      name: "forgot",
      component: Forgot,
    },
    // Reset
    {
      path: "/reset",
      name: "reset",
      component: Reset,
    },
    // Activate
    {
      path: "/activate",
      name: "activate",
      component: Activate,
    },
    // Profile
    {
      path: "/profile",
      meta: {
        title: "Profile",
      },
      name: "profile",
      component: () => import("./views/Profile.vue"),
    },
    // Admin
    {
      path: "/admin",
      meta: {
        title: "Admin",
      },
      name: "admin",
      component: () => import("./views/BoardAdmin.vue"),
    },
    // Manager
    {
      path: "/manager",
      meta: {
        title: "Manager",
      },
      name: "manager",
      component: () => import("./views/Manager.vue"),
    },
    // User
    {
      path: "/user",
      meta: {
        title: "User",
      },
      name: "user",
      component: () => import("./views/BoardUser.vue"),
    },
    // MEMBERS ROUTES
    {
      path: "/members",
      meta: {
        title: "Members",
      },
      name: "members",
      component: () => import("./components/members/MemberList"),
    },
    // BRANCHES ROUTES
    {
      path: "/branches",
      meta: {
        title: "Branches",
      },
      name: "branches",
      component: () => import("./components/branches/BranchList"),
    },
    // STATES ROUTES
    {
      path: "/states",
      meta: {
        title: "States",
      },
      name: "states",
      component: () => import("./components/states/StateList"),
    },
    // REGION ROUTES
    {
      path: "/districts",
      meta: {
        title: "Districts",
      },
      name: "districts",
      component: () => import("./components/regions/RegionList"),
    },

    // TATCHART ROUTES
    {
      path: "/tatcharts",
      meta: {
        title: "TAT Charts",
      },
      name: "tatcharts",
      component: () => import("./components/tatcharts/TatChartList"),
    },

    // HANDLER ROUTES
    {
      path: "/handlers",
      meta: {
        title: "Claim Handler",
      },
      name: "handler",
      component: () => import("./components/handler/handler"),
    },
    // BULLETIN ROUTES
    {
      path: "/bulletins",
      meta: {
        title: "Bulletin",
      },
      name: "bulletins",
      component: () => import("./components/bulletins/bulletinList"),
    },
    // DEPARTMENTS ROUTES
    {
      path: "/depts",
      meta: {
        title: "Departments",
      },
      name: "depts",
      component: () => import("./components/depts/DeptList"),
    },

    // FILE CLASSIFICATION ROUTES ROUTES
    {
      path: "/subDepts",
      meta: {
        title: "File Classification",
      },
      name: "subDepartments",
      component: () => import("./components/subDepts/subDeptList"),
    },

    // INSURER ROUTES
    {
      path: "/inss",
      meta: {
        title: "Insurer",
      },
      name: "inss",
      component: () => import("./components/inss/InsList"),
    },

    // CLAIMS
    {
      path: "/claims",
      meta: {
        title: "Claims",
      },
      name: "claimsList",
      component: () => import("./components/claims/claimsList"),
    },

    // DROPBOX FILE ROUTES
    {
      path: "/dropboxfiles",
      meta: {
        title: "Dropbox Files",
      },
      name: "dropboxfiles",
      component: () => import("./components/dropboxfiles/DropboxfileList"),
    },
    // REPORTS ROUTES
    {
      path: "/reports",
      meta: {
        title: "Basic Reports",
      },
      name: "reports",
      component: () => import("./components/reports/reports"),
    },
    {
      path: "/reportsTwo",
      meta: {
        title: "Detail Reports",
      },
      name: "reportsTwo",
      component: () => import("./components/reports/reportsTwo"),
    },
    {
      path: "/byref/:id",
      meta: {
        title: "Detail By Department",
      },
      name: "refTabDetail",
      component: () => import("./views/detailView/refTabDetail"),
    },
    {
      path: "/bybranch/:id",
      meta: {
        title: "Detail by Branch",
      },
      name: "brTabDetail",
      component: () => import("./views/detailView/brTabDetail"),
    },
    {
      path: "/detailByRef/:fileStatus/:refType",
      meta: {
        title: "Detail By Reference",
      },
      name: "detail-by-ref",
      component: () => import("./views/detailView/detailByRef"),
    },
    {
      path: "/detailByBranch/:fileStatus/:branch",
      meta: {
        title: "Detail By Branch",
      },
      name: "detail-by-branch",
      component: () => import("./views/detailView/detailByBranch"),
    },
    // File
    {
      path: "/file/:id",
      meta: {
        title: "Casefile Details",
      },
      name: "vuexFile",
      component: () => import("./views/detailView/vuexFile"),
    },
    {
      path: "/dopFile/:id",
      meta: {
        title: "Dropbox File Details",
      },
      name: "dropboxDetail",
      component: () => import("./views/detailView/dropboxDetail"),
    },

    {
      path: "/findfile/:id",
      meta: {
        title: "Casefile Details",
      },
      name: "findfile",
      component: () => import("./views/detailView/quickFindDetail.vue"),
    },

    // MISC ROUTES
    {
      path: "/soon",
      meta: {
        title: "Soon",
      },
      name: "soon",
      component: () => import("./components/Soon"),
    },

    // Merimen Routes
    {
      path: "/merimen-data",
      name: "merimen-data",
      meta: {
        title: "Merimen Data",
      },
      component: () => import("./components/merimen/MerimenDataList.vue"),
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: { requiresAuth: true, title: "dashboard", keepAlive: true },
    },
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("./views/Welcome.vue"),
      meta: { title: "welcome" },
    },

    {
      path: "/advanced-search",
      name: "advancedSearch",
      component: AdvancedSearch,
      meta: {
        requiresAuth: true,
        title: "Advanced Search",
      },
    },

    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFound,
    },
  ],
});
router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/forgot", "/reset", "/activate"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
