<template>
  <body>
    <v-row>
      <v-col cols="12" color="white" class="white--text text-center">
        <!-- <h1 class="ma-3 pa-3">
          Welcome to Associated Adjusters Online Reporting System
        </h1> -->
        <v-card-text
          class="ma-3 pa-3 font-weight-bold"
          :class="{
            'text-h4': $vuetify.breakpoint.mdAndDown,
            'text-h1': $vuetify.breakpoint.lgAndUp,
          }"
          >Welcome to Associated Adjusters Online Reporting System</v-card-text
        >
      </v-col>
    </v-row>
    <v-card class="pa-auto ma-auto" outlined tile color="transparent">
      <div>
        <v-row class="pa-0">
          <v-col cols="12" lg="3"></v-col>
          <v-col
            cols="12"
            lg="3"
            md="12"
            sm="12"
            class="fill-height d-flex justify-center"
          >
            <v-card tile max-width="350px" class="pa-10 text-center">
              <div>
                <!-- <img id="profile-img" src="../assets/aasb-logo.png" /> -->
                <img width="100%" src="../assets/aasb-logo.png" />
                <form ref="form" @submit.prevent="handleLogin">
                  <v-row no-gutters>
                    <v-col cols="12" class="ma-0">
                      <v-text-field
                        v-model="user.email"
                        name="email"
                        value=""
                        type="text"
                        label="Email"
                        autocomplete="email"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-text-field
                        v-model="user.password"
                        name="password"
                        type="password"
                        label="Password"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        tile
                        block
                        :disabled="isEmptyForm"
                        class="primary"
                        type="submit"
                      >
                        <!-- <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span> -->
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
                <v-row class="mt-1">
                  <v-card-text class="text-right ma-0 pa-0">
                    <v-btn plain text small to="/forgot">
                      Forgot Password
                    </v-btn>
                  </v-card-text>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-none d-md-none d-lg-flex justify-center"
          >
            <v-card flat class="transparent" width="100%">
              <v-col class="pa-0 text-start">
                <v-system-bar window color="#0c4c94" dark>
                  <v-icon>mdi-message</v-icon>
                  <span>Latest News</span>
                  <v-spacer></v-spacer>
                  <v-icon>mdi-minus</v-icon>
                  <v-icon>mdi-checkbox-blank-outline</v-icon>
                  <v-icon>mdi-close</v-icon>
                </v-system-bar>
              </v-col>
              <v-col
                v-for="bulletin in bulletins"
                :key="bulletin.id"
                v-bind="bulletin.item"
              >
                <v-alert border="left" color="#04934c" dense type="info"
                  >{{ bulletin.newsTitle }}<br />
                  <small
                    ><p>
                      {{ bulletin.newsStory }}
                    </p></small
                  >

                  <p>- {{ bulletin.newsAuthor }}</p>

                  <p>
                    {{ formatDate(bulletin.createdAt) }}
                  </p></v-alert
                >
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" class="d-lg-none d-flex justify-center">
            <v-card max-width="600px" flat class="transparent px-3">
              <v-row>
                <v-col
                  cols="12"
                  class="align-center justify-center text-center"
                >
                  <v-system-bar window color="#0c4c94" dark>
                    <v-icon>mdi-message</v-icon>
                    <span>Latest News</span>
                    <v-spacer></v-spacer>
                    <v-icon>mdi-minus</v-icon>
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                    <v-icon>mdi-close</v-icon>
                  </v-system-bar>
                </v-col>
                <v-col
                  cols="12"
                  v-for="bulletin in bulletins"
                  :key="bulletin.id"
                  v-bind="bulletin.item"
                  class="py-0"
                >
                  <v-alert border="left" color="#04934c" dense type="info">
                    {{ bulletin.newsTitle }} - {{ bulletin.newsAuthor }} -
                    {{ formatDate(bulletin.createdAt)
                    }}<span
                      style="cursor: pointer"
                      class="pl-3 caption"
                      @click="something(bulletin.id)"
                      >... read more</span
                    >
                  </v-alert>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="newsDialog">
      <v-card>
        <v-card-text>
          <v-card-title>{{ newBulletin.newsTitle }}</v-card-title>
          <v-card-text class="text-subtitle-2">{{
            newBulletin.newsStory
          }}</v-card-text>
          <v-card-text class="text-caption"
            >- {{ newBulletin.newsAuthor }} -
            {{ formatDate(newBulletin.createdAt) }}</v-card-text
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </body>
</template>

<script>
import User from "../models/user";
import BulletinDataService from "../services/BulletinDataService";
import moment from "moment";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      // message: "",
      bulletins: [],
      newsDialog: false,
      newBulletin: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isEmptyForm() {
      return !(this.user.email && this.user.password);
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    something(item) {
      // alert("Hello");

      this.newsDialog = true;
      let result = this.bulletins.filter((a) => a.id === item);
      return (this.newBulletin = result[0]);
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY LT");
    },

    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.email && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/welcome"); // Changed from /home to /welcome
            },
            (error) => {
              this.loading = false;
              // this.message =
              //   (error.response &&
              //     error.response.data &&
              //     error.response.data.message) ||
              //   error.message ||
              //   error.toString();

              this.$store.dispatch("setSnackbar", {
                showing: true,
                text:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  toString(),
              });
            }
          );
        }
      });
    },

    retrieveBulletins() {
      BulletinDataService.getAll()
        .then((response) => {
          // this.bulletins = response.data.map(this.getDisplayBulletin);
          this.bulletins = response.data.slice(-2).reverse();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.retrieveBulletins();
    },

    getDisplayBulletin(bulletin) {
      return {
        id: bulletin.id,
        newsTitle:
          bulletin.newsTitle.length > 100
            ? bulletin.newsTitle.substr(0, 100) + "..."
            : bulletin.newsTitle,
        newsStory:
          bulletin.newsStory.length > 200
            ? bulletin.newsStory.substr(0, 200) + "..."
            : bulletin.newsStory,
        newsAuthor:
          bulletin.newsAuthor.length > 30
            ? bulletin.newsAuthor.substr(0, 30) + "..."
            : bulletin.newsAuthor,
      };
    },
  },
  mounted() {
    this.retrieveBulletins();
  },
};
</script>

<style scoped>
body {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
    url(../assets/login-bg.jpg) no-repeat;
  width: 100%;
  height: 100vh;

  background-position: center;
}
/* body {
  height: 100vh;
  width: 100%;
  background-image: url(../assets/login-bg.jpg);
  background-size: cover;
} */
</style>
