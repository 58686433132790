import CasefileDataService from "@/services/CasefileDataService";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    casefiles: [],
    lastFetch: null,
    isLoading: false,
    activeFilters: {},
    filteredCasefiles: [],
    cacheTimeout: 5 * 60 * 1000, // 5 minutes
    currentUserId: null, // Add this
  },

  getters: {
    getFilteredCasefiles:
      (state) => (userRoles, userDepts, userBranches, userId) => {
        // Add validation checks
        if (!userRoles || !userDepts || !userBranches || !userId) {
          return [];
        }

        if (!state.casefiles.length) {
          return [];
        }

        if (!state.filteredCasefiles.length) {
          let filtered = state.casefiles.map((file) => ({
            ...file,
            days: moment().diff(moment(file.dateOfAssign), "days"),
            // Add a computed property for TAT status
            tatStatus: null, // Will be calculated in component
          }));

          // Apply role-based filtering
          if (
            userRoles.includes("MANAGER") ||
            userRoles.includes("ADMIN") ||
            userRoles.includes("CLERK")
          ) {
            state.filteredCasefiles = filtered;
          } else if (
            userRoles.includes("BRANCHCLERK") ||
            userRoles.includes("BRANCHMANAGER")
          ) {
            filtered = filtered.filter(
              (file) =>
                userDepts.includes(file.refType) &&
                userBranches.includes(file.branch)
            );
            state.filteredCasefiles = filtered;
          } else if (userRoles.includes("ADJUSTER")) {
            filtered = filtered.filter(
              (file) =>
                userDepts.includes(file.refType) &&
                userBranches.includes(file.branch) &&
                file.adjuster === userId
            );
            state.filteredCasefiles = filtered;
          }
        }
        return state.filteredCasefiles;
      },

    needsRefresh: (state) => {
      return (
        !state.lastFetch || Date.now() - state.lastFetch > state.cacheTimeout
      );
    },
  },

  mutations: {
    SET_CASEFILES(state, casefiles) {
      state.casefiles = casefiles;
      state.lastFetch = Date.now();
    },
    SET_LOADING(state, status) {
      state.isLoading = status;
    },
    UPDATE_CASEFILE(state, updatedFile) {
      const index = state.casefiles.findIndex(
        (file) => file.id === updatedFile.id
      );
      if (index !== -1) {
        state.casefiles.splice(index, 1, updatedFile);
      }
    },
    CLEAR_FILTERED_CACHE(state) {
      state.filteredCasefiles = [];
    },
    CLEAR_STORE(state) {
      state.casefiles = [];
      state.lastFetch = null;
      state.filteredCasefiles = [];
      state.currentUserId = null;
      state.isLoading = false; // Add this to ensure loading state is reset
    },
    SET_CURRENT_USER(state, userId) {
      if (state.currentUserId !== userId) {
        state.currentUserId = userId;
        state.casefiles = [];
        state.lastFetch = null;
        state.filteredCasefiles = [];
      }
    },
    UPDATE_TAT_STATUS(state, { id, status }) {
      const file = state.filteredCasefiles.find((f) => f.id === id);
      if (file) {
        file.tatStatus = status;
      }
    },
  },

  actions: {
    async fetchCasefiles({ commit, getters, state }, userId) {
      // Add user check before fetching
      if (state.currentUserId !== userId) {
        commit("SET_CURRENT_USER", userId);
      }

      if (!getters.needsRefresh && state.casefiles.length > 0) return;

      commit("SET_LOADING", true);
      try {
        const response = await CasefileDataService.getAll();
        const activeCases = response.data.filter(
          (item) => item.fileStatus !== "CLO" && item.fileStatus !== "CANC"
        );
        commit("SET_CASEFILES", activeCases);
      } catch (error) {
        console.error("Error fetching casefiles:", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async updateCasefile({ commit }, payload) {
      try {
        const response = await CasefileDataService.update(payload.id, payload);
        commit("UPDATE_CASEFILE", response.data);
        commit("CLEAR_FILTERED_CACHE");
        return response.data;
      } catch (error) {
        console.error("Error updating casefile:", error);
        throw error;
      }
    },

    clearStore({ commit }) {
      commit("CLEAR_STORE");
    },
  },
};
