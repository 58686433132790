<template>
  <div>
    <v-container fluid style="width: 80%" class="text-center">
      <v-tabs v-model="selectedTabIndex">
        <v-tab
          @click="
            dept = true;
            branch = false;
          "
          >Department</v-tab
        >
        <v-tab
          @click="
            branch = true;
            dept = false;
          "
          >Branch</v-tab
        >
      </v-tabs>

      <deptTab v-if="dept" />
      <brTab v-if="branch" />
    </v-container>
  </div>
</template>

<script>
import deptTab from "../components/tabs/department.vue";
import brTab from "../components/tabs/branch.vue";

export default {
  name: "listing",
  components: {
    deptTab,
    brTab,
  },
  data() {
    return {
      dept: true,
      branch: false,
      selectedTabIndex: 0,
    };
  },
  created() {
    // Check for stored tab preference
    const storedTab = localStorage.getItem("selectedTab");
    if (storedTab === "branch") {
      this.dept = false;
      this.branch = true;
      this.selectedTabIndex = 1;
    } else {
      this.dept = true;
      this.branch = false;
      this.selectedTabIndex = 0;
    }

    // Remove the stored preference after using it
    localStorage.removeItem("selectedTab");
  },
};
</script>
