<template>
  <div>
    <v-app-bar
      v-if="currentUser"
      :color="appBarColor"
      :elevation="elevation"
      app
      fixed
      dense
      :class="{ 'app-bar-shadow': isScrolled }"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <!-- Title for mobile/small screens -->
        <span class="d-block d-sm-none">Document Management</span>

        <!-- Icons wrapper with responsive display -->
        <div class="d-none d-sm-block">
          <!-- Statistics Button -->
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/dashboard"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-monitor-dashboard</v-icon>
              </v-btn>
            </template>
            <span>Dashboard</span>
          </v-tooltip>

          <!-- Home Button -->
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/home"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-home-circle</v-icon>
              </v-btn>
            </template>
            <span> Home</span>
          </v-tooltip>

          <!-- Listing Button -->
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/listing"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-format-list-bulleted</v-icon></v-btn
              >
            </template>
            <span> Listing</span>
          </v-tooltip>

          <!-- Report Button -->
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/reports"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-clipboard-file</v-icon></v-btn
              >
            </template>
            <span>Basic Report Generator</span>
          </v-tooltip>

          <!-- Report Button Two-->
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/reportsTwo"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-clipboard-search</v-icon></v-btn
              >
            </template>
            <span>Detail Report Generator</span>
          </v-tooltip>

          <!-- Claims Button -->
          <!-- <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/claims"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-cash-multiple</v-icon></v-btn
              >
            </template>
            <span>Claims</span>
          </v-tooltip> -->

          <!-- Merimen Button -->
          <v-tooltip v-if="showMerimen" bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="indigo"
                to="/merimen-data"
                icon
                style="text-decoration: none"
              >
                <v-icon>mdi-inbox-arrow-down</v-icon></v-btn
              >
            </template>
            <span>Merimen</span>
          </v-tooltip>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn to="/profile" class="text-decoration-none" icon small>
        <v-avatar size="36px">
          <v-img
            v-if="currentUser.profile"
            :src="currentUser.profile"
            :alt="currentUser.username"
            @error="handleAvatarError"
          >
            <template v-slot:placeholder>
              <v-icon>mdi-account</v-icon>
            </template>
          </v-img>
          <v-icon v-else>mdi-account</v-icon>
        </v-avatar>
      </v-btn>

      <div v-if="!currentUser">
        <v-btn to="/login" class="text-decoration-none" plain
          ><font-awesome-icon icon="sign-in-alt" />Login
        </v-btn>
      </div>

      <div v-else>
        <v-tooltip bottom transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.prevent="logOut"
              v-bind="attrs"
              v-on="on"
              icon
              style="text-decoration: none"
            >
              <v-icon>mdi-logout-variant</v-icon></v-btn
            >
          </template>
          <span>Logout</span>
        </v-tooltip>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      app
      temporary
      expand-on-hover
      dark
      src="../assets/v-nav-bg.jpg"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.username }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ currentUser.email }}
            </v-list-item-subtitle>
            <v-divider></v-divider>
            <v-list-item-subtitle class="text-h7">
              <strong>Access:</strong>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="text-h8">
              <ul>
                <li v-for="(role, index) in currentUser.roles" :key="index">
                  {{ role }}
                </li>
              </ul>
            </v-list-item-subtitle>
            <v-divider></v-divider>

            <div v-if="currentUser.branches && currentUser.branches.length">
              <v-list-item-subtitle class="text-h7">
                <strong>Branch:</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-h7">
                <ul>
                  <li v-for="(j, index) in currentUser.branches" :key="index">
                    <div v-for="(k, index) in filterStates(j)" :key="index">
                      {{ k.name.toUpperCase() }}
                    </div>
                  </li>
                </ul>
              </v-list-item-subtitle>
            </div>
            <div v-if="currentUser.dept && currentUser.dept.length">
              <v-list-item-subtitle class="text-h7">
                <strong>Department:</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-h7">
                <ul>
                  <li v-for="(i, index) in currentUser.dept" :key="index">
                    <div v-for="(m, index) in filterDept(i)" :key="index">
                      {{ m.name.toUpperCase() }}
                    </div>
                  </li>
                </ul>
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- admin links -->
      <v-list v-if="showAdminBoard" nav dense>
        <div v-for="(link, i) in adminlinks" :key="i">
          <v-list-group
            v-if="link.subLinks"
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="activeMenu === 'admin'"
            @click="handleMenuClick('admin')"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              class="text-decoration-none pl-5"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <!-- User Links -->
      <v-list nav dense>
        <div v-for="(link, i) in links" :key="i">
          <v-list-group
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="activeMenu === 'dashboard'"
            @click="handleMenuClick('dasboard')"
            active-class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in filteredSubLinks(link)"
              :to="sublink.to"
              :key="sublink.text"
              class="text-decoration-none pl-5"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <!-- <v-btn text :to="{ name: 'listing' }">Listing</v-btn> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import BranchDataService from "../services/BranchDataService";
import DeptDataService from "../services/DeptDataService";

import EventBus from "../common/EventBus";

export default {
  components: {},
  name: "appBar",

  data: () => ({
    searchId: "",
    drawer: false,
    adminlinks: [
      {
        icon: "mdi-shield-lock-outline",
        text: "Admin",
        subLinks: [
          {
            text: "User",
            to: "/members",
            icon: "mdi-account-group", // Users/members icon
          },
          {
            text: "Branch",
            to: "/branches",
            icon: "mdi-office-building", // Building/branch icon
          },
          {
            text: "Department",
            to: "/depts",
            icon: "mdi-domain", // Organization/department icon
          },
          {
            text: "File Classification",
            to: "/subDepts",
            icon: "mdi-file-tree", // File hierarchy icon
          },
          {
            text: "State",
            to: "/states",
            icon: "mdi-map-marker", // Location/state icon
          },
          {
            text: "District",
            to: "/districts",
            icon: "mdi-map", // Region/district icon
          },
          {
            text: "Insurer",
            to: "/inss",
            icon: "mdi-shield-check", // Insurance/protection icon
          },
          {
            text: "TAT List",
            to: "/tatcharts",
            icon: "mdi-clock-outline", // Time/duration icon
          },
          {
            text: "Handler",
            to: "/handlers",
            icon: "mdi-account-tie", // Professional/handler icon
          },
          {
            text: "Bulletin",
            to: "/bulletins",
            icon: "mdi-bulletin-board", // Bulletin board icon
          },
        ],
      },
    ],

    links: [
      {
        icon: "mdi-menu",
        text: "Navigation",
        subLinks: [
          {
            to: "/dashboard",
            icon: "mdi-monitor-dashboard",
            text: "Dashboard",
          },
          {
            to: "/home",
            icon: "mdi-home-circle-outline",
            text: "Home",
          },
          // {
          //   to: "/caselist",
          //   icon: "mdi-format-list-bulleted",
          //   text: "Case List",
          // },
          {
            to: "/listing",
            icon: "mdi-format-list-bulleted",
            text: "Listing",
          },
          {
            to: "/reports",
            icon: "mdi-clipboard-file",
            text: "Basic Report Generator",
          },
          {
            to: "/reportsTwo",
            icon: "mdi-clipboard-search",
            text: "Detail Report Generator",
          },
          // {
          //   to: "/claims",
          //   icon: "mdi-cash-multiple",
          //   text: "Claims",
          // },
          {
            text: "Merimen",
            to: "/merimen-data",
            icon: "mdi-inbox-arrow-down", // Data/records icon
          },
        ],
      },
    ],
    // specialLinks: [
    //   {
    //     to: "/dropboxfiles",
    //     icon: "mdi-dropbox",
    //     text: "Dropbox",
    //   },
    // ],
    branches: [],
    departments: [],
    activeMenu: null, // Add this to track which menu is active
    isScrolled: false,
    elevation: 1,
    appBarColor: "transparent",
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ADMIN");
      }

      return false;
    },
    // showDropBox() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     let result = this.currentUser.roles.filter(
    //       (data) =>
    //         data.includes("ADMIN") ||
    //         // data.includes("BRANCHMANAGER") ||
    //         // data.includes("BRANCHCLERK") ||
    //         data.includes("CLERK")
    //     );
    //     if (result.length > 0) {
    //       return true;
    //     }
    //   }

    //   return false;
    // },
    showMerimen() {
      if (this.currentUser && this.currentUser.roles) {
        return (
          this.currentUser.roles.includes("ADMIN") ||
          this.currentUser.roles.includes("MANAGER") ||
          this.currentUser.roles.includes("CLERK")
        );
      }
      return false;
    },
    // showManagerBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes("MANAGER");
    //   }

    //   return false;
    // },
  },
  methods: {
    filterStates(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },
    retrieveStates() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // onEnter: function (value) {
    //   this.$router.push({
    //     name: "file",
    //     params: { id: value },
    //   });
    // },
    logOut() {
      this.$store.dispatch("setSnackbar", {
        showing: true,
        color: "success",
        text: "Successfully Logged Out!",
      });
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    // Add new method to handle menu clicks
    handleMenuClick(menuName) {
      if (this.activeMenu === menuName) {
        this.activeMenu = null;
      } else {
        this.activeMenu = menuName;
      }
    },
    filteredSubLinks(link) {
      return link.subLinks.filter(
        (sublink) => sublink.text !== "Merimen" || this.showMerimen
      );
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
      this.elevation = this.isScrolled ? 6 : 1;
      this.appBarColor = this.isScrolled ? "white" : "transparent";
    },
    handleAvatarError() {
      this.$store.state.auth.user.profile = null;
    },
  },

  watch: {
    // Add watcher for route changes
    $route() {
      // Reset activeMenu when route changes (optional)
      // this.activeMenu = null;
    },
  },

  mounted() {
    this.retrieveStates();
    this.retrieveDepts();
    EventBus.on("logout", () => {
      this.logOut();
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    EventBus.remove("logout");
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.app-bar-shadow {
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9) !important;
}
</style>
