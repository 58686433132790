<template>
  <v-app>
    <!-- Remove duplicate router-view structure -->
    <div id="the-content">
      <v-main>
        <app-bar />
        <page-route-title
          v-if="
            !['login', 'forgot', 'reset', 'activate', 'NotFound'].includes(
              $route.name
            )
          "
        />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </v-main>
    </div>
    <!-- <v-overlay :opacity="1" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay> -->
    <br />
    <staticFooter />
    <v-scale-transition group>
      <template>
        <v-snackbar
          v-for="snackbar in visibleSnackbars"
          :key="snackbar.id"
          v-model="snackbar.showing"
          :color="snackbar.color"
          :timeout="timeout"
          :style="`margin-bottom: ${visibleSnackbars.indexOf(snackbar) * 60}px`"
          @input="onSnackbarHide(snackbar.id)"
          app
          right
          :elevation="6"
          shaped
          class="custom-snackbar"
        >
          <v-card flat :color="snackbar.color" dark class="d-flex align-center">
            <v-icon left class="ml-2" size="20">
              {{ getSnackbarIcon(snackbar.color) }}
            </v-icon>
            <span class="mx-3 font-weight-medium">{{ snackbar.text }}</span>
            <v-btn icon x-small @click="snackbar.showing = false">
              <v-icon size="16">mdi-close</v-icon>
            </v-btn>
          </v-card>
        </v-snackbar>
      </template>
    </v-scale-transition>
    <!-- v-snackbar previous -->
    <!-- <template>
      <v-scale-transition>
        <v-snackbar
          v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
          :key="snackbar.text + Math.random()"
          v-model="snackbar.showing"
          :timeout="2000"
          :color="snackbar.color"
          :style="`bottom: ${index * 60 + 8}px`"
          transition="scale-transition"
        >
          {{ snackbar.text }}

          <v-btn text @click="snackbar.showing = false"> Close </v-btn>
        </v-snackbar>
      </v-scale-transition>
    </template> -->
    <!-- GO TO TOP SCROLLER -->
    <v-fab-transition>
      <v-btn
        v-show="offsetTop"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        color="primary"
        @click="toTop"
        class="to-top-button"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import staticFooter from "./components/staticFooter.vue";
import appBar from "./components/appBar.vue";
import PageRouteTitle from "./components/page-route-title.vue";

export default {
  data: () => ({
    fab: false,
    // overlay: true,
    timeout: 2000, // Reduced timeout for better performance
    left: true,
    bottom: true,
    offsetTop: 0, // Add this property
  }),
  mounted() {
    // hide the overlay when everything has loaded
    // you could choose some other event, e.g. if you're loading
    // data asynchronously, you could wait until that process returns
    this.overlay = false;
  },
  components: {
    staticFooter,
    appBar,
    PageRouteTitle,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    ...mapState(["snackbars"]),
    visibleSnackbars() {
      return this.snackbars.filter((s) => s.showing).slice(0, 3);
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.offsetTop = top > 20; // Update offsetTop instead of fab
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getSnackbarIcon(color) {
      switch (color) {
        case "success":
          return "mdi-check-circle-outline";
        case "error":
          return "mdi-alert-circle-outline";
        case "warning":
          return "mdi-alert-outline";
        case "info":
          return "mdi-information-outline";
        default:
          return "mdi-bell-outline";
      }
    },
    onSnackbarHide(snackbarId) {
      this.$store.commit("REMOVE_SNACKBAR", snackbarId);
    },
  },
  watch: {
    snackbars: {
      handler(newSnackbars) {
        if (newSnackbars.length > 10) {
          this.$store.commit("CLEANUP_OLD_SNACKBARS");
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "./assets/styles/snackbar.css";

.v-card--material {
  position: relative;
  /* top: -64px; */
  top: 20px;
  /* margin-bottom: -32px; */
}

.v-cardheading {
  position: relative;
  top: -25px;
  transition: 0.3s ease;
  z-index: 1;
  text-align: end;
}

/* Add these new styles */
.custom-snackbar {
  border-radius: 8px !important;
  min-width: 300px !important;
  max-width: 600px !important;
  transform-origin: right center;
}

.v-snack__wrapper {
  min-height: 48px !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.v-snack__content {
  padding: 0 !important;
}

.v-card {
  width: 100%;
}

/* Animation for multiple snackbars */
.v-scale-transition-enter-active,
.v-scale-transition-leave-active {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.v-scale-transition-enter,
.v-scale-transition-leave-to {
  opacity: 0;
  transform: scale(0.5) translateX(100px);
}

.to-top-button {
  z-index: 1000;
  margin-bottom: 40px !important; /* Add margin to clear the footer */
  margin-right: 16px !important;
}
</style>
