import CasefileDataService from "../../services/CasefileDataService";
import moment from "moment";

export const dashboard = {
  namespaced: true,
  state: {
    isLoading: false,
    statistics: {
      totalActiveCases: 0,
      overdueCount: 0,
      completedThisMonth: 0,
      newCasesToday: 0,
      pendingInvestigation: 0,
      pendingEditing: 0,
    },
    topAdjusters: [],
    topBranches: [],
    topInsurers: [],
    topEditors: [],
    deptPerformance: [],
    caseCategories: [],
    monthlyTrends: {
      values: [0, 0, 0, 0, 0, 0], // Default 6 months
      labels: [], // Will be populated with last 6 months
      trend: 0,
    },
    upcomingDeadlines: [],
    systemNotifications: [],
    recentDocuments: [],
    pendingActions: [],
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.isLoading = loading;
    },
    SET_STATISTICS(state, stats) {
      state.statistics = { ...state.statistics, ...stats };
    },
    SET_TOP_ADJUSTERS(state, data) {
      state.topAdjusters = data;
    },
    SET_TOP_BRANCHES(state, data) {
      state.topBranches = data;
    },
    SET_TOP_INSURERS(state, data) {
      state.topInsurers = data;
    },
    SET_TOP_EDITORS(state, data) {
      state.topEditors = data;
    },
    SET_DEPT_PERFORMANCE(state, data) {
      state.deptPerformance = data;
    },
    SET_CASE_CATEGORIES(state, data) {
      state.caseCategories = data;
    },
    SET_MONTHLY_TRENDS(state, data) {
      state.monthlyTrends = data;
    },
    SET_UPCOMING_DEADLINES(state, data) {
      state.upcomingDeadlines = data;
    },
    SET_SYSTEM_NOTIFICATIONS(state, data) {
      state.systemNotifications = data;
    },
    SET_RECENT_DOCUMENTS(state, data) {
      state.recentDocuments = data;
    },
    SET_PENDING_ACTIONS(state, data) {
      state.pendingActions = data;
    },
  },
  actions: {
    async fetchStatistics({ commit }, payload) {
      commit("SET_LOADING", true);
      try {
        if (!payload) {
          const response = await CasefileDataService.getAll();
          const cases = response.data;
          const today = moment().startOf("day");
          const monthStart = moment().startOf("month");

          payload = {
            totalActiveCases: cases.filter(
              (c) => c.fileStatus !== "CLO" && c.fileStatus !== "CANC"
            ).length,
            overdueCount: cases.filter((c) => {
              const days = moment().diff(moment(c.dateOfAssign), "days");
              return (
                days > 30 && c.fileStatus !== "CLO" && c.fileStatus !== "CANC"
              );
            }).length,
            completedThisMonth: cases.filter(
              (c) =>
                c.fileStatus === "CLO" &&
                moment(c.dateOfAssign).isAfter(monthStart)
            ).length,
            newCasesToday: cases.filter((c) =>
              moment(c.dateOfAssign).isSame(today, "day")
            ).length,
            pendingInvestigation: cases.filter((c) => c.fileStatus === "INVE")
              .length,
            pendingEditing: cases.filter((c) => c.fileStatus === "PEDI").length,
          };
        }
        commit("SET_STATISTICS", payload);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async fetchTopAdjusters({ commit }, data) {
      if (!data) {
        const cases = await CasefileDataService.getAll().then(
          (res) => res.data
        );
        // Calculate top adjusters
        data = Object.values(
          cases.reduce((acc, caseItem) => {
            if (!acc[caseItem.adjuster]) {
              acc[caseItem.adjuster] = {
                id: caseItem.adjuster,
                name: caseItem.adjusterName || "Unknown Adjuster",
                active: 0,
                total: 0,
                performance: 0,
              };
            }
            acc[caseItem.adjuster].total++;
            if (
              caseItem.fileStatus !== "CLO" &&
              caseItem.fileStatus !== "CANC"
            ) {
              acc[caseItem.adjuster].active++;
            }
            acc[caseItem.adjuster].performance = Math.round(
              (acc[caseItem.adjuster].active / acc[caseItem.adjuster].total) *
                100
            );
            return acc;
          }, {})
        )
          .sort((a, b) => b.performance - a.performance)
          .slice(0, 5);
      }
      commit("SET_TOP_ADJUSTERS", data);
    },

    async fetchTopBranches({ commit }, data) {
      if (!data) {
        const cases = await CasefileDataService.getAll().then(
          (res) => res.data
        );
        // Calculate top branches
        data = Object.values(
          cases.reduce((acc, caseItem) => {
            if (!acc[caseItem.branch]) {
              acc[caseItem.branch] = {
                id: caseItem.branch,
                name: caseItem.branchName || "Unknown Branch",
                caseCount: 0,
                performance: 0,
                completed: 0,
              };
            }
            acc[caseItem.branch].caseCount++;
            if (caseItem.fileStatus === "CLO") {
              acc[caseItem.branch].completed++;
            }
            acc[caseItem.branch].performance = Math.round(
              (acc[caseItem.branch].completed /
                acc[caseItem.branch].caseCount) *
                100
            );
            return acc;
          }, {})
        )
          .sort((a, b) => b.performance - a.performance)
          .slice(0, 5);
      }
      commit("SET_TOP_BRANCHES", data);
    },

    async fetchTopInsurers({ commit }, data) {
      commit("SET_TOP_INSURERS", data);
    },
    async fetchTopEditors({ commit }, data) {
      commit("SET_TOP_EDITORS", data);
    },
    async fetchDeptPerformance({ commit }, data) {
      commit("SET_DEPT_PERFORMANCE", data);
    },
    async fetchCaseCategories({ commit }, data) {
      commit("SET_CASE_CATEGORIES", data);
    },
    async fetchMonthlyTrends({ commit }, data) {
      if (!data) {
        const cases = await CasefileDataService.getAll().then(
          (res) => res.data
        );
        const last6Months = Array.from({ length: 6 }, (_, i) => {
          const month = moment().subtract(i, "months");
          return {
            month: month,
            label: month.format("MMM"),
            value: 0,
          };
        }).reverse();

        // Count cases per month
        cases.forEach((c) => {
          const caseMonth = moment(c.dateOfAssign);
          const monthIndex = last6Months.findIndex((m) =>
            m.month.isSame(caseMonth, "month")
          );
          if (monthIndex !== -1) {
            last6Months[monthIndex].value++;
          }
        });

        // Calculate trend
        const values = last6Months.map((m) => m.value);
        const trend =
          values.length >= 2
            ? ((values[values.length - 1] - values[values.length - 2]) /
                values[values.length - 2]) *
              100
            : 0;

        data = {
          values: values,
          labels: last6Months.map((m) => m.label),
          trend: Math.round(trend),
        };
      }
      commit("SET_MONTHLY_TRENDS", data);
    },

    async fetchSystemNotifications({ commit }, data) {
      if (!data) {
        data = [
          {
            id: 1,
            type: "info",
            message: "Welcome to your dashboard",
          },
        ];
      }
      commit("SET_SYSTEM_NOTIFICATIONS", data);
    },

    async fetchUpcomingDeadlines({ commit }, data) {
      if (!data) {
        const cases = await CasefileDataService.getAll().then(
          (res) => res.data
        );
        data = cases
          .filter((c) => c.fileStatus !== "CLO" && c.fileStatus !== "CANC")
          .map((c) => ({
            id: c.id,
            caseNumber: c.caseNumber,
            daysLeft: moment(c.dateOfAssign)
              .add(30, "days")
              .diff(moment(), "days"),
          }))
          .filter((d) => d.daysLeft > 0 && d.daysLeft <= 14)
          .sort((a, b) => a.daysLeft - b.daysLeft)
          .slice(0, 5);
      }
      commit("SET_UPCOMING_DEADLINES", data);
    },

    async fetchRecentDocuments({ commit }, data) {
      if (!data) {
        data = []; // Add your document fetch logic here
      }
      commit("SET_RECENT_DOCUMENTS", data);
    },

    async fetchPendingActions({ commit }, data) {
      if (!data) {
        data = []; // Add your pending actions logic here
      }
      commit("SET_PENDING_ACTIONS", data);
    },
  },
  getters: {
    statisticsCards: (state) => {
      return [
        {
          label: "Active Cases",
          value: state.statistics.totalActiveCases,
          color: "primary",
        },
        {
          label: "Overdue Cases",
          value: state.statistics.overdueCount,
          color: "warning",
        },
        {
          label: "Completed This Month",
          value: state.statistics.completedThisMonth,
          color: "success",
        },
        {
          label: "New Cases Today",
          value: state.statistics.newCasesToday,
          color: "info",
        },
        {
          label: "Under Investigation",
          value: state.statistics.pendingInvestigation,
          color: "deep-orange",
        },
        {
          label: "Pending Editing",
          value: state.statistics.pendingEditing,
          color: "purple",
        },
      ];
    },
  },
};

export default dashboard;
