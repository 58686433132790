<template>
  <div class="simple-footer">
    <span v-if="currentUser" class="user-name">{{ currentUser.fullname }}</span>
    <span class="copyright">
      Licenced Loss Adjusters & Investigation &copy;
      {{ new Date().getFullYear() }}
    </span>
  </div>
</template>

<script>
export default {
  name: "staticFooter",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped>
.simple-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #a5d6a7; /* green lighten-3 equivalent */
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
}

.user-name {
  @media (max-width: 600px) {
    display: none;
  }
}
</style>
